import React, { useCallback } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { Box, Typography, useTheme } from "@mui/material";

import { MAIN_STYLES } from "@constants";
import { hotel_plus_icon } from "@assets";
import { StyledToggleButton } from "./ToggleButtonsMultiSelect/styles";

interface Props {
  data?: any[];
  onRemove?: (data: any) => void;
}

const OptionGroup: React.FC<Props> = ({ data = [], onRemove }) => {
  const theme = useTheme();

  const handleOnRemove = useCallback(
    (item: any) => {
      onRemove && onRemove(item);
    },
    [onRemove]
  );

  return (
    <>
      <Box
        sx={{
          gap: "10px",
          flexWrap: "wrap",
          borderRadius: "8px",
          flexDirection: "row",
          backgroundColor: theme.colors.customize.purple1,
        }}
        p={"10px"}
        mt={"20px"}
        display={"flex"}
        justifyContent={"center"}
      >
        {[1, 2, 3, 4, 5, 6].map((_, index) => {
          if (typeof data?.[index] === "undefined") {
            return <img src={hotel_plus_icon} alt="hotel_plus_icon" />;
          } else {
            return (
              <StyledToggleButton
                key={index}
                value={index}
                sx={{
                  fontSize: "11px",
                  backgroundColor: MAIN_STYLES.mainPurple,
                }}
                onClick={() => handleOnRemove(data[index])}
              >
                <Typography
                  style={{
                    color: "white",
                    fontSize: "11px",
                    fontWeight: "700",
                    lineHeight: "15px",
                  }}
                >
                  {data[index].name}
                </Typography>
                <ClearIcon style={{ color: "white", fontSize: "15px" }} />
              </StyledToggleButton>
            );
          }
        })}
      </Box>
    </>
  );
};

export default OptionGroup;
