import React, { useRef, useState, useEffect, useCallback } from "react";

import {
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  BarController,
  LineController,
  Chart as ChartJS,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { Box, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import TooltipChart from "./Tooltip";

import { MixChartData, MixChartTooltip } from "./types";
import { CATEGORY_COLOR, LINE_COLOR, SOLID_COLOR } from "./constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController
);

interface ChartItemProp {
  backgroundColor?: string;
  data: number[];
  label: string;
}
interface Data {
  labels: string[];
  datasets: any;
}

interface Props {
  canNext?: boolean;
  maxXaxis?: number;
  currentColumn: number;
  canPrevious?: boolean;
  chartData?: MixChartData;
  tooltipData?: MixChartTooltip[];
  onNext?: (callback?: () => void) => void;
  onChangeColumn?: (column: number) => void;
  onPrevious?: (callback?: () => void) => void;
}

const MAX_COLUMN = 5;
const MixedChart: React.FC<Props> = ({
  chartData,
  tooltipData,
  currentColumn,
  maxXaxis = 1,
  canNext = true,
  canPrevious = true,
  onNext,
  onPrevious,
  onChangeColumn,
}) => {
  const activeIndexRef = useRef(currentColumn);
  const chartRef = useRef<ChartJS | null>(null);

  const [data, setData] = useState<Data | null>(null);
  const [tooltipVisible, setTooltipVisible] = useState<{
    display: boolean;
    top: number;
    left: number;
  }>({ display: false, top: 0, left: 0 });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCurrentIndex] = useState<number>(currentColumn);
  const [selectedTooltipData, setSelectedTooltipData] = useState<
    MixChartTooltip | undefined
  >();

  const resetActiveIndex = () => {
    setCurrentIndex(MAX_COLUMN);
    activeIndexRef.current = MAX_COLUMN;
  };

  const handlePrevious = () => {
    onPrevious && onPrevious(resetActiveIndex);
  };

  const handleNext = () => {
    onNext && onNext(resetActiveIndex);
  };

  useEffect(() => {
    let datasets: any = [];
    const solidChart = chartData?.dataChart?.solidChart;
    if (solidChart) {
      solidChart.forEach((_: ChartItemProp, index: number) => {
        datasets.push({
          type: "line",
          data: _.data,
          label: _.label,
          fill: false,
          backgroundColor: SOLID_COLOR[index].color,
          borderColor: SOLID_COLOR[index].color,
          borderDash: [5, 5],
          yAxisID: index === 0 ? "y1" : "y2",
          pointBackgroundColor: "white",
          pointBorderColor: SOLID_COLOR[index].color,
          pointStyle: "circle",
        });
      });
    }
    const lineChart = chartData?.dataChart?.lineChart;
    if (lineChart) {
      lineChart.forEach((_: ChartItemProp, index: number) => {
        datasets.push({
          type: "line",
          data: _.data,
          label: _.label,
          borderColor: LINE_COLOR[index].color,
          borderWidth: 2,
          fill: false,
          yAxisID: "y",
          pointBackgroundColor: "white",
          pointBorderColor: LINE_COLOR[index].color,
          pointStyle: "circle",
        });
      });
    }
    const barChart = chartData?.dataChart?.barChart;
    if (barChart) {
      barChart.forEach((_: ChartItemProp, index: number) => {
        datasets.push({
          type: "bar",
          label: _.label,
          data: _.data,
          backgroundColor:
            CATEGORY_COLOR[_.label as keyof typeof CATEGORY_COLOR],
          borderWidth: 1,
          borderColor: "grey",
          barThickness: 82,
          borderRadius: 10,
        });
      });
    }

    setData({
      labels: chartData?.label || [],
      datasets: datasets,
    });
  }, [
    chartData?.dataChart?.barChart,
    chartData?.dataChart?.lineChart,
    chartData?.dataChart?.solidChart,
    chartData?.label,
  ]);

  const handleTooltip = useCallback(
    (context: any) => {
      const { chart, tooltip } = context;
      const tooltipDisplay = tooltipVisible.display;

      if (tooltip.opacity === 0) {
        if (tooltipDisplay) {
          setTooltipVisible({ display: false, top: 0, left: 0 });
        }
      } else {
        if (tooltipDisplay) return;

        const position = chart.canvas.getBoundingClientRect();
        const gap = position.width > 1200 ? 150 : 130;
        const windowW = window.innerWidth - 250;
        const result = position.right - tooltip.caretX - windowW / 2 - 200;

        const columnWidth = chart.width / 6;
        const index = Math.floor(
          (chart.width - (chart.width - tooltip.caretX)) / columnWidth
        );

        if (tooltipData?.length) {
          setSelectedTooltipData(tooltipData[index]);
        }

        setTooltipVisible({
          display: true,
          top: position.top,
          left: result > 0 ? tooltip.caretX + gap : tooltip.caretX - gap - 400,
        });
      }
    },
    [tooltipData, tooltipVisible.display]
  );

  const options: any = {
    maintainAspectRatio: false,
    animation: false,
    scales: {
      y: {
        stacked: true,
        min: 0,
        max: Math.round(maxXaxis / 10) * 10 + 20,
      },
      y1: {
        position: "right",
        stacked: false,
        min: 0,
        max: 10,
        grid: {
          drawOnChartArea: false,
        },
      },
      y2: {
        stacked: false,
        min: 0,
        max: Math.round(maxXaxis / 10) * 10 + 20,
        display: false,
      },
      x: {
        stacked: true,
        borderWidth: 2,
        barThickness: 20,
        ticks: {
          font: {
            size: 16,
            weight: "bold",
          },
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: handleTooltip,
      },
    },
    onClick: (event: any, _: any, chart: any) => {
      const clickX = event.x;
      const columnWidth = chart.width / 6;
      const clickedColumnIndex = Math.floor(
        (chart.width - (chart.width - clickX)) / columnWidth
      );
      activeIndexRef.current = clickedColumnIndex;
      setCurrentIndex(clickedColumnIndex);
      onChangeColumn && onChangeColumn(clickedColumnIndex);
    },
  };

  const chartBackgroundPlugin = {
    id: "chartBackgroundPlugin",
    beforeDraw: (chart: any) => {
      if (activeIndexRef.current !== null) {
        const ctx = chart.ctx;
        const chartArea = chart.chartArea;
        const xAxis = chart.scales.x;

        const barWidth =
          xAxis.getPixelForTick(1) -
          xAxis.getPixelForTick(0) -
          (chart.options.scales.x.ticks.padding || 0);

        const barLeft =
          xAxis.getPixelForTick(activeIndexRef.current) - barWidth / 2;

        ctx.save();
        ctx.globalCompositeOperation = "destination-over";
        ctx.fillStyle = "#AD00FF0D";

        ctx.fillRect(
          barLeft,
          chartArea.top,
          barWidth,
          chartArea.bottom - chartArea.top
        );

        ctx.restore();
      }
    },
  };

  return (
    <>
      {data && (
        <>
          <Box
            sx={{
              width: "100%",
              height: "500px",
              position: "relative",
            }}
          >
            <IconButton
              onClick={handlePrevious}
              disabled={canPrevious}
              sx={{ position: "absolute", top: "50%", left: -45 }}
            >
              <ChevronLeftIcon
                style={{
                  height: 50,
                  width: 50,
                }}
              />
            </IconButton>
            <Chart
              type="bar"
              data={data}
              ref={chartRef}
              height={"500px"}
              options={options}
              plugins={[chartBackgroundPlugin]}
            />
            <IconButton
              disabled={canNext}
              onClick={handleNext}
              sx={{ position: "absolute", top: "50%", right: -45 }}
            >
              <ChevronRightIcon
                style={{
                  height: 50,
                  width: 50,
                }}
              />
            </IconButton>
          </Box>
          {tooltipVisible.display && selectedTooltipData && (
            <div
              style={{
                position: "absolute",
                top: tooltipVisible.top,
                left: tooltipVisible.left,
                background: "white",
                padding: "30px",
                borderRadius: "12px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
              }}
            >
              <TooltipChart {...selectedTooltipData} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MixedChart;
