const logo = require("./logo.png");
const congra = require("./congra.png");
const white_logo = require("./white_logo.png");
const success_icon = require("./success_icon.png");
const white_smile_icon = require("./white_smiley.png");
const hotel_plus_icon = require("./hotel_plus_ic.png");
const yanolja_icon = require("./yanolja_icon.png");
const booking_icon = require("./booking_icon.png");
const agoda_icon = require("./agoda_icon.png");
const expedia_icon = require("./expedia_icon.png");
const goodchoice_icon = require("./goodchoice_icon.png");
const warning_circle = require("./warning-circle.png");

const booking = require("./suppliers/booking.png");
const expedia = require("./suppliers/expedia.png");
const yanolja = require("./suppliers/yanolja.png");
const goodchoice = require("./suppliers/goodchoice.png");
const agoda = require("./suppliers/agoda.png");

const cat = require("./cat.png");
const polygon = require("./polygon.png");
const purple_circle_mail = require("./purple_circle_mail.png");
const purple_circle_phone = require("./purple_circle_phone.png");

const welcome_popup = require("./welcome_popup.png");
const launching_popup = require("./launching_popup.png");
const libby_service_manual = require("./docs/libby_service_manual.pdf");
const caret_right = require("./caret-right/caret-right.png");

const check_circle = require("./check-circle/check-circle.png");
const red_warning_circle = require("./warning-circle/warning-circle.png");

const pencil_line_active = require("./pencil-line.png");
const pencil_line = require("./pencil-line-gray.png");

const map_pin = require("./map-pin.png");
const map_pin_fill = require("./map-pin-fill.png");

const magnifying_glass = require("./magnifying-glass-gray.png");
const magnifying_glass_active = require("./magnifying-glass.png");
const pencil_simple = require("./pencil-simple.png");

export {
  cat,
  logo,
  agoda,
  congra,
  booking,
  expedia,
  yanolja,
  polygon,
  white_logo,
  goodchoice,
  yanolja_icon,
  booking_icon,
  expedia_icon,
  success_icon,
  warning_circle,
  hotel_plus_icon,
  goodchoice_icon,
  white_smile_icon,
  purple_circle_mail,
  purple_circle_phone,
  welcome_popup,
  launching_popup,
  libby_service_manual,
  caret_right,
  check_circle,
  red_warning_circle,
  agoda_icon,
  pencil_line,
  pencil_line_active,
  map_pin,
  map_pin_fill,
  magnifying_glass,
  magnifying_glass_active,
  pencil_simple
};
