import React, { useEffect, useState } from "react";
import {
  Grid,
  Select,
  styled,
  MenuItem,
  Typography,
  SelectChangeEvent,
} from "@mui/material";

interface Props {
  label: string;
  value?: string;
  options: { label: string; value: string }[];
  onChange?: (value: string | undefined) => void;
}

const Container = styled(Grid)({
  display: "flex",
  flexDirection: "row",
});

const Title = styled(Typography)({
  color: "#111111",
  fontSize: "16px",
  fontWeight: "700",
  padding: "0 10px",
  alignSelf: "center",
  fontFamily: "Pretendard Variable, monospace",
});

const MainSelect = styled(Select)({
  flex: 1,
  height: "36px",
  borderRadius: "5px",
  alignSelf: "center",
});

const FuncComponent: React.FC<Props> = ({
  label,
  options,
  value = "none",
  onChange,
}) => {
  const [defaultValue, setDefaultValue] = useState<string | undefined>(
    value || "none"
  );

  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    setDefaultValue(event.target.value);
    onChange &&
      onChange(event.target.value === "none" ? undefined : event.target.value);
  };

  useEffect(() => {
    setDefaultValue(value || "none");
  }, [value]);

  return (
    <Container>
      <Title sx={{ paddingBottom: 0, marginRight: "10px" }}>{label}</Title>
      <MainSelect
        displayEmpty
        value={defaultValue}
        onChange={handleSelectChange}
        defaultValue={defaultValue}
      >
        {options.map((_, index: number) => (
          <MenuItem key={index} value={_.value}>
            {_.label}
          </MenuItem>
        ))}
      </MainSelect>
    </Container>
  );
};

export default FuncComponent;
