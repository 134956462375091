import React, { useState } from "react";

import {
  Box,
  List,
  Grid,
  Radio,
  IconButton,
  Typography,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";

import InfiniteScroll from "react-infinite-scroller";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";

import BoxFlex from "@components/BoxFlex";
import UsedReply from "@components/UsedReply";

import { IReview } from "@models";
import { IMetaData } from "@models";
import { MAIN_STYLES } from "@constants";
import { CustomListItem } from "./styles";

interface UsedRepliesProps {
  meta?: IMetaData;
  reviewId?: number;
  replies: IReview[];
  loadMore: (page: number) => void;
  handleChange?: (item: IReview) => void;
  handleWarningDialog: (id: number) => void;
  handleChooseMainReply: (item: IReview) => void;
}

const DEFAULT_TITLE = "자동 회신";
const { purple3, mainPurple } = MAIN_STYLES;

const UsedReplies: React.FC<UsedRepliesProps> = ({
  meta,
  replies,
  loadMore,
  handleChange,
  handleWarningDialog,
  handleChooseMainReply,
}) => {
  const [selectedItem, setSelectedItem] = useState<IReview>();

  const handleToggle = (item: IReview) => {
    setSelectedItem(item);
    handleChooseMainReply(item);
  };

  const handleOnChange = (item: IReview) => {
    handleChange && handleChange(item);
  };

  return (
    <List sx={{ margin: "0 30px", padding: "0px" }}>
      <InfiniteScroll
        pageStart={1}
        loadMore={loadMore}
        hasMore={meta ? meta?.currentPage < meta?.totalPages : true}
        loader={
          <Grid
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress disableShrink />
          </Grid>
        }
      >
        {replies.map((item, index) => (
          <CustomListItem key={item.id}>
            <Box sx={{ width: "100%", padding: "0" }}>
              <BoxFlex>
                <FormControlLabel
                  label={
                    <>
                      <Typography
                        color="inherit"
                        sx={{ fontWeight: "700", fontSize: "16px" }}
                      >
                        {item.title === DEFAULT_TITLE
                          ? `즐겨찾기 ${index + 1}`
                          : item.title}
                      </Typography>
                    </>
                  }
                  control={
                    <Radio
                      key={item.id}
                      checked={selectedItem?.id === item.id}
                      onChange={() => handleToggle(item)}
                      value={item}
                      name="radio-buttons-group"
                      sx={{
                        color:
                          selectedItem?.id === item.id ? mainPurple : "default",
                        "&.Mui-checked": {
                          color:
                            selectedItem?.id === item.id
                              ? mainPurple
                              : "default",
                        },
                      }}
                    />
                  }
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Box>
                    <IconButton onClick={() => handleWarningDialog(item.id)}>
                      <DeleteIcon color={"error"} />
                    </IconButton>
                    <IconButton onClick={() => handleOnChange(item)}>
                      <EditIcon sx={{ color: "#999999" }} />
                    </IconButton>
                  </Box>
                </Box>
              </BoxFlex>
              <Box sx={{ bgcolor: purple3, borderRadius: "12px" }}>
                <UsedReply review={item} />
              </Box>
            </Box>
          </CustomListItem>
        ))}
      </InfiniteScroll>
    </List>
  );
};

export default UsedReplies;
