import React from "react";
import styled from "styled-components";
import { View } from "./screens";
import { ITextSize, ITextWeight } from "@models";
import { useTextStyle } from "@hooks";

const TextContainer = styled.div`
  white-space: pre-line;
`;

interface TextProps extends React.PropsWithChildren {
  size?: ITextSize;
  weight?: ITextWeight;
  color?: string;
  underline?: boolean;
  handleMouseOver?: () => void;
  handleMouseUp?: () => void;
  handleMouseDown?: () => void;
}

export function Text({
  size,
  color,
  weight,
  underline,
  handleMouseOver,
  handleMouseUp,
  handleMouseDown,
  ...props
}: TextProps) {
  const styles = useTextStyle({ size, color, weight, underline });

  return (
    <TextContainer
      style={{ ...styles }}
      {...props}
      onMouseOver={handleMouseOver}
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
    >
      {props.children}
    </TextContainer>
  );
}

export function TitleAndCount({
  title,
  count,
}: {
  title: string;
  count?: number;
}) {
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <Text size={"xs"}>{`${title} (${count})`}</Text>
    </View>
  );
}

/*
<Text size={"xs"}>{title}</Text>
      <Spacing width={3} />
      {count !== undefined && <Text size={"xs"}>{`(${count})`}</Text>}
*/
