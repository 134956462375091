import React, { useMemo } from "react";

import { Box, Button, Grid, Modal, Typography } from "@mui/material";

import { IButton } from "@models";
import { MAIN_STYLES } from "@constants";

interface Props {
  title: string;
  message: string;
  visible: boolean;
  buttons?: IButton[];
  onClose: () => void;
}

const style = {
  p: 4,
  top: "50%",
  left: "50%",
  width: 400,
  boxShadow: 24,
  border: "2px solid #000",
  bgcolor: "background.paper",
  transform: "translate(-50%, -50%)",
  position: "absolute" as "absolute",
};

const FuncComponent: React.FC<Props> = ({
  title,
  message,
  visible,
  buttons = [],
  onClose,
}) => {
  const renderButtons = useMemo(() => {
    if (buttons.length === 0) {
      return (
        <Button sx={{ mt: 2 }} variant="contained" fullWidth onClick={onClose}>
          OK
        </Button>
      );
    } else {
      return (
        <Grid
          sx={{ mt: 2 }}
          style={{ display: "flex", flexDirection: "row", gap: 10 }}
        >
          {buttons.map((button, index) => (
            <Button
              key={"btn_" + index}
              fullWidth
              style={{
                backgroundColor: button?.color ?? MAIN_STYLES.mainPurple,
              }}
              variant="contained"
              onClick={button.onClick}
            >
              {button.title}
            </Button>
          ))}
        </Grid>
      );
    }
  }, [buttons, onClose]);

  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {message}
        </Typography>
        {renderButtons}
      </Box>
    </Modal>
  );
};

export default FuncComponent;
