import {
  IBusinessVerifyPayload,
  IContactUsPayload,
  IRegisterPayload,
  SendPhoneNumberOtpPayload,
} from "@models";
import { apiInstance } from "@utils";

class AuthenApi {
  private static URL = {
    // GETME: 'auth/getNe',
    LOGIN: "auth/login",
    LOGOUT: "auth/logout",
    BUSINESS_VERIFY: "auth/register/business-verify",
    CHECK_EXISTED_EMAIL: "auth/register/exists-email",
    SEND_EMAIL_VERIFY_CODE: "auth/register/send-email-verify",
    VERIFY_EMAIL_CODE: "auth/register/verify-email",
    SEND_PHONE_NUMBER_OTP: "auth/register/send-phone-number-verify",
    VERIFY_PHONE_CODE: "auth/register/verify-phone-number",
    REGISTER: "auth/register",
    FIND_BUSINESS_EMAIL: "auth/find-email/exists-business-number",
    CONTACT_US: "contact-us",
    SEND_FORGOT_PASSWORD_EMAIL: "auth/forgot-password/send-email",
    VERIFY_FORGOT_PASSWORD_OTP: "auth/forgot-password/verify-otp",
    CHANGE_PASSWORD: "auth/forgot-password/reset-password",
    UPDATE_PHONE_CODE_SEND: "auth/update-user-profile/send-otp",
    UPDATE_PHONE_VERIFY_CODE: "auth/update-user-profile/verify-otp",
  };

  // static getMe = async (payload: any) => {
  //   return apiInstance
  //     .get(this.URL.LOGIN, {
  //       withCredentials: true,
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       ...payload  // 기존 payload가 있다면 spread
  //     })
  //     .then((response) => {
  //       return response;
  //     })
  //     .catch((error) => {
  //       return error;
  //     });
  // };

  static login = async (payload: any) => {
    return apiInstance
      .post(this.URL.LOGIN, payload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static logout = async () => {
    return apiInstance
      .put(this.URL.LOGOUT)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static verifyBusinessNumber = async (data: IBusinessVerifyPayload) => {
    return apiInstance
      .post(this.URL.BUSINESS_VERIFY, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static checkExistedEmail = async (email: string) => {
    return apiInstance
      .post(this.URL.CHECK_EXISTED_EMAIL, { email })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static sendEmailVerifyCode = async (email: string) => {
    return apiInstance
      .post(this.URL.SEND_EMAIL_VERIFY_CODE, { email })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static verifyEmailCode = async (code: string) => {
    return apiInstance
      .post(this.URL.VERIFY_EMAIL_CODE, { OTP: code })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static sendPhoneVerifyCode = async (data: SendPhoneNumberOtpPayload) => {
    return apiInstance
      .post(this.URL.SEND_PHONE_NUMBER_OTP, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static verifyPhoneNumberCode = async (code: string) => {
    return apiInstance
      .post(this.URL.VERIFY_PHONE_CODE, { OTP: code })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static register = async (data: IRegisterPayload) => {
    return apiInstance
      .post(this.URL.REGISTER, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static findEmailByBusinessInfo = async (
    businessNumber: string,
    ceoName: string
  ) => {
    return apiInstance
      .post(this.URL.FIND_BUSINESS_EMAIL, {
        businessNumber,
        ceoName,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static contactUs = async (data: IContactUsPayload) => {
    return apiInstance
      .post(this.URL.CONTACT_US, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static sendForgotPasswordLink = async (email: string) => {
    return apiInstance
      .post(this.URL.SEND_FORGOT_PASSWORD_EMAIL, { email })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static verifyForgotPasswordCode = async (code: string) => {
    return apiInstance
      .post(this.URL.VERIFY_FORGOT_PASSWORD_OTP, { OTP: code })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static changePassword = async (password: string, uuid: string) => {
    return apiInstance
      .put(this.URL.CHANGE_PASSWORD, { password, uuid })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static sendUpdatePhoneVerifyCode = async (
    data: SendPhoneNumberOtpPayload
  ) => {
    return apiInstance
      .post(this.URL.UPDATE_PHONE_CODE_SEND, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  };

  static verifyUpdatePhoneNumberCode = async (code: string) => {
    return apiInstance
      .post(this.URL.UPDATE_PHONE_VERIFY_CODE, { OTP: code })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export default AuthenApi;
