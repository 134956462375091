import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { SidebarLayout } from "@layouts";

const LandingPage = lazy(() => import("@pages/landing/Landing"));
const DashboardPage = lazy(() => import("@pages/dashboard"));
const HomePage = lazy(() => import("@pages/home"));
const DetailedAnalysisPage = lazy(() => import("@pages/detailedAnalysis"));
const PeriodAnalysisPage = lazy(() => import("@pages/periodAnalysis"));
const NotFoundPage = lazy(() => import("@pages/NotFound"));
const FirstSettingsPage = lazy(() => import("@pages/settings"));
const UserSettingsPage = lazy(() => import("@pages/userSettings"));
const ChatbotSettingsPage = lazy(() => import("@pages/chatbot/chatbotSettings"));
const MessageSettingsPage = lazy(() => import("@pages/chatbot/messageSettings"));
const AnalysisByPeriodPage = lazy(() => import("@pages/vsMarket/analysisByPeriod"));
const DetailedAnalysisVSMarketPage = lazy(() => import("@pages/vsMarket/detailedAnalysis"));
const CustomerServiceCenterPage = lazy(() => import("@pages/customerService"));


const UserRoutes: RouteObject[] = [
  {
    path: "/",
    element: <SidebarLayout />,
    children: [
      {
        path: "/dashboard",
        element: <DashboardPage />,
      },
      {
        path: "/home",
        element: <HomePage />,
      },
      {
        path: "/detailed-analysis/:reviewId?",
        element: <DetailedAnalysisPage />,
      },
      {
        path: "/period-analysis",
        element: <PeriodAnalysisPage />,
      },
      {
        path: "/detailed-analysis",
        element: <DetailedAnalysisPage />,
      },
      {
        path: "/detailed-analysis-by-period",
        element: <DetailedAnalysisVSMarketPage />,
      },
      {
        path: "/analysis-by-period",
        element: <AnalysisByPeriodPage />,
      },
      {
        path: "/settings",
        element: <UserSettingsPage />,
      },
      {
        path: "/chatbot-settings",
        element: <ChatbotSettingsPage />,
      },
      {
        path: "/chatbot-messages",
        element: <MessageSettingsPage />,
      },
      {
        path: "/first-settings",
        element: <FirstSettingsPage />,
      },
      {
        path: "/customer-service-center",
        element: <CustomerServiceCenterPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
      
    ],
  },
];

export default UserRoutes;
