import React, { useEffect, useState } from "react";
import {
  Grid,
  Select,
  styled,
  MenuItem,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  value?: string;
  onChange?: (value: string | undefined) => void;
}

const Container = styled(Grid)({
  display: "flex",
  flexDirection: "row",
});

const Title = styled(Typography)({
  color: "#111111",
  fontSize: "16px",
  fontWeight: "700",
  padding: "0 10px",
  alignSelf: "center",
  fontFamily: "Pretendard Variable, monospace",
});

const MainSelect = styled(Select)({
  height: "36px",
  minWidth: "150px",
  borderRadius: "5px",
  alignSelf: "center",
});

const FuncComponent: React.FC<Props> = ({ value = "none", onChange }) => {
  const { t } = useTranslation();
  const [defaultValue, setDefaultValue] = useState<string | undefined>(
    value || "none"
  );

  const OTA_OPTIONS = [
    { value: "none", label: t("ota_select.entire") },
    { value: "YANOLJA", label: t("ota_select.yanolja") },
    { value: "GOODCHOICE", label: t("ota_select.goodchoice") },
    { value: "BOOKING", label: "Booking.com" },
    { value: "EXPEDIA", label: "Expedia" },
    { value: "AGODA", label: "Agoda" },
  ];

  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    setDefaultValue(event.target.value);
    onChange &&
      onChange(event.target.value === "none" ? undefined : event.target.value);
  };

  useEffect(() => {
    setDefaultValue(value || "none");
  }, [value]);

  return (
    <Container>
      <Title sx={{ paddingBottom: 0 }}>OTA</Title>
      <MainSelect
        displayEmpty
        value={defaultValue}
        onChange={handleSelectChange}
        defaultValue={defaultValue}
      >
        {OTA_OPTIONS.map((_, index: number) => (
          <MenuItem key={index} value={_.value}>
            {_.label}
          </MenuItem>
        ))}
      </MainSelect>
    </Container>
  );
};

export default FuncComponent;
