import qs from "qs";
import { apiInstance } from "@utils";
import { GetUserParams } from "@models";

class UserApi {
  private static URL = {
    USERS: "users",
    SUB_USERS: "users/sub-account",
    DELETE_SUB_USERS: "users/sub-account",
    MY_PROFILE: "users/me",
    GET_SUB_ACCOUNT: "users/:id/sub-account",
    ADMIN_DELETE_ACCOUNT: "users/admin-delete-users",
    ADMIN_UPDATE_DELETE_SUB_ACCOUNT: "users/:userId/sub-account/:subAccountId",
    ADMIN_GET_USER_GROUP: "users/groups",
    MY_BANNERS: "banners/my-banners",
    USER_PAYMENT: "users/payment-info",
  };

  static updateUserCompetitors = async (competitorIds: any) => {
    return apiInstance
      .put(`${this.URL.USERS}`, {
        competitorHotels: competitorIds,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static getUserPaymentInfo = async () => {
    return apiInstance
      .get(this.URL.USER_PAYMENT)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("the following error occured during the user payment info");
        return error;
      });
  };

  static getSubUsers = async () => {
    return apiInstance
      .get(`${this.URL.SUB_USERS}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("THIS IS THE ERROR");
        console.error(error);
        return error;
      });
  };

  static deleteSubUser = async (user_id: number) => {
    return apiInstance
      .delete(`${this.URL.DELETE_SUB_USERS}/${user_id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static updateSubUser = async (user_id: number, data: any) => {
    return apiInstance
      .put(`${this.URL.SUB_USERS}/${user_id}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static createSubUser = async (data: any) => {
    return apiInstance
      .post(`${this.URL.SUB_USERS}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static updateUserGeneric = async (data: any) => {
    return apiInstance
      .put(`${this.URL.USERS}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("the following error occured during the user update");
        return error;
      });
  };

  static getMyProfile = async () => {
    return apiInstance
      .get(this.URL.MY_PROFILE)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static getUsers = async (params: GetUserParams) => {
    return apiInstance
      .get(`${this.URL.USERS}?${qs.stringify(params)}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static getUserById = async (id: string) => {
    return apiInstance
      .get(`${this.URL.USERS}/${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static getSubAccount = async (id: string) => {
    return await apiInstance.get(
      `${this.URL.GET_SUB_ACCOUNT.replace(":id", id)}?limit=10&page=1`
    );
  };

  static updateAdminUserGeneric = async (id: string, data: any) => {
    return apiInstance
      .put(`${this.URL.USERS}/${id}`, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("the following error occured during the user update");
        return error;
      });
  };

  static deleteAdminAccount = async (data: any) => {
    return apiInstance.put(this.URL.ADMIN_DELETE_ACCOUNT, data);
  };

  static deleteAdminGroupAccount = async (
    userId: string,
    subAccountId: number
  ) => {
    return apiInstance.delete(`users/${userId}/sub-account/${subAccountId}`);
  };

  static updateAdminGroupAccount = async (
    userId: string,
    subAccountId: number,
    data: any
  ) => {
    return apiInstance.put(`users/${userId}/sub-account/${subAccountId}`, data);
  };

  static getUserGroups = async (params: GetUserParams) => {
    return apiInstance
      .get(`${this.URL.ADMIN_GET_USER_GROUP}?${qs.stringify(params)}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static createUserGroup = async (data: any) => {
    return apiInstance.post(this.URL.ADMIN_GET_USER_GROUP, data);
  };

  static deleteUserGroup = async (id: number) => {
    return apiInstance.delete(`${this.URL.ADMIN_GET_USER_GROUP}/${id}`);
  };

  static updateUserGroup = async (id: string, data: any) => {
    return apiInstance.patch(`${this.URL.ADMIN_GET_USER_GROUP}/${id}`, data);
  };

  static getUserGroup = async (id: string) => {
    return apiInstance.get(`${this.URL.ADMIN_GET_USER_GROUP}/${id}`);
  };

  static getUserGroupMembers = async (id: string, params: any) => {
    return apiInstance.get(
      `${this.URL.ADMIN_GET_USER_GROUP}/${id}/members?${qs.stringify(params)}`
    );
  };

  static addOrRemoveMember = async (
    id: string,
    method: "ADD" | "REMOVE",
    members: { userId: number | undefined; hotelId: number | undefined }[]
  ) => {
    return apiInstance.put(`${this.URL.ADMIN_GET_USER_GROUP}/${id}/members`, {
      method,
      members,
    });
  };

  static getMyBanners = async () => {
    return apiInstance.get(`${this.URL.MY_BANNERS}?limit=100&page=1`);
  };
}

export default UserApi;
