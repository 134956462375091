import moment from "moment";

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function isValidDate(dateStr: string) {
  // Check if the format is correct (YYYYMMDD)
  if (!/^\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/.test(dateStr)) {
    return false;
  }

  const year = parseInt(dateStr.substring(0, 4), 10);
  const month = parseInt(dateStr.substring(4, 6), 10) - 1; // JS months are 0-indexed
  const day = parseInt(dateStr.substring(6, 8), 10);

  // Check if the date is valid
  const date = new Date(year, month, day);
  return (
    date.getFullYear() === year &&
    date.getMonth() === month &&
    date.getDate() === day
  );
}

export function convertDateFormat(dateStr: string) {
  if (!/^\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/.test(dateStr)) {
    return "Invalid Format";
  }

  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(4, 6);
  const day = dateStr.substring(6, 8);

  return `${year}-${month}-${day}`;
}

export function maskEmail(email: string, digits: number = 2) {
  const [username, domain] = email.split("@");
  if (username.length < digits) {
    return email; // Not enough length to mask
  }
  const visibleChars = username.substring(0, digits); // Keep the first two characters visible
  const maskedChars = "*".repeat(username.length - digits); // Replace the rest with asterisks
  return `${visibleChars}${maskedChars}@${domain}`;
}

export function getStartThisWeek() {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const distanceToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Adjust if Sunday is the start of the week

  // Calculate the date of Monday
  const monday = new Date(today);
  monday.setDate(monday.getDate() + distanceToMonday);

  return monday;
}

export function getEndThisWeek() {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const distanceToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Adjust if Sunday is the start of the week

  // Calculate the date of Monday
  const monday = new Date(today);
  monday.setDate(monday.getDate() + distanceToMonday);

  const sunday = new Date(monday);
  sunday.setDate(sunday.getDate() + 6);

  return sunday;
}

export function getCurrentMonthStartAndEnd() {
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  let currentMonth = currentDate.getMonth();

  let startDate = new Date(currentYear, currentMonth, 1);

  let endDate = new Date(currentYear, currentMonth + 1, 0);

  return { startDate, endDate };
}

export function formatYearWeekFromString(yearWeek: string) {
  // Split the string to get year and week number parts
  const [weekStr, yearStr] = yearWeek.split("-");
  const year = parseInt(yearStr);
  const week = parseInt(weekStr);

  const date = moment().year(year).isoWeek(week).startOf("isoWeek");

  // Determine the first week of the month
  const firstWeekOfMonth = moment(date).startOf("month").isoWeek();

  // Calculate which nth week in the month
  const weekInMonth = date.isoWeek() - firstWeekOfMonth + 1;

  const formattedDate = date.format(`YYYY.MM [${weekInMonth}주차]`);
  return formattedDate;
}

export function formatBusinessNumber(number: string) {
  if (number?.length < 10) return number;
  return `${number?.slice(0, 3)}-${number?.slice(3, 7)}-${number?.slice(7)}`;
}
