import { useCallback, useContext, useState } from "react";

import {
  Box,
  Drawer,
  styled,
  Divider,
  useTheme,
  Button,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import LogoutIcon from "@mui/icons-material/Logout";
import { useRecoilValue, useResetRecoilState } from "recoil";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import SidebarMenu from "./SidebarMenu";
import Logo from "@components/LogoSign";
import { NoticeModal } from "@components";
import Scrollbar from "@components/Scrollbar";

import { MAIN_STYLES } from "@constants";
import { resetStorageItem } from "@utils";
import AuthenApi from "@services/auth.api";
import { STRIP_BANNER, USER_INFO } from "@states";
import { SidebarContext } from "@contexts/SidebarContext";

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        display: flex;
        flex-direction: column;
`
);

function Sidebar() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useRecoilValue(USER_INFO);
  const closeSidebar = () => toggleSidebar();
  const stripBanner = useRecoilValue(STRIP_BANNER);
  const resetUserState = useResetRecoilState(USER_INFO);
  const [visible, setVisible] = useState<boolean>(false);
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  const handleLogout = useCallback(async () => {
    await AuthenApi.logout();
    resetUserState();
    resetStorageItem();
    navigate("/sign-in");
    window.location.replace('http://heroworksin.iptime.org:30000/?method=logout')
  }, [navigate, resetUserState]);

  return (
    <>
      <SidebarWrapper
        sx={{
          left: 0,
          top: 0,
          color: "white",
          display: "flex",
          position: "fixed",
          background: "#111111",
          boxShadow:
            theme.palette.mode === "dark" ? theme.sidebar.boxShadow : "none",
          paddingTop: stripBanner ? "80px" : "",
        }}
      >
        <Scrollbar>
          <Box mt={3} mb={"50px"}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                marginLeft: "30px",
              }}
            >
              <Logo />
            </Box>
          </Box>
          <SidebarMenu />
        </Scrollbar>
        <Box display="flex" flexDirection="column" sx={{ pl: 3, py: 1 }}>
          <Box>
            <Button
              variant="text"
              color="inherit"
              component={RouterLink}
              sx={{ color: "white", pl: 0, fontSize: "14px" }}
              startIcon={<SettingsOutlinedIcon />}
              to="/settings"
            >
              {t("sidebar_menu.preferences")}
            </Button>
          </Box>
          <Box>
            <Button
              variant="text"
              color="inherit"
              component={RouterLink}
              to="/customer-service-center"
              startIcon={<PhoneOutlinedIcon />}
              sx={{ color: "white", pl: 0, fontSize: "13px" }}
            >
              {t("sidebar_menu.customer_service_center")}
            </Button>
          </Box>
        </Box>
        <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10],
          }}
        />
        <Box display="flex" sx={{ pl: 3, py: 1 }}>
          <Box flex={1}>
            <Typography variant="body1" fontSize="1rem">
              {t("sidebar_menu.my_hotel")}
            </Typography>
            <Typography variant="body1" fontSize="0.75rem">
              {user?.email}
            </Typography>
          </Box>
          <Button
            variant="text"
            color="primary"
            startIcon={<LogoutIcon />}
            onClick={() => setVisible(true)}
          />
        </Box>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background: "#111111",
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Logo />
              </Box>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10],
              }}
            />
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
      <NoticeModal
        visible={visible}
        onClose={() => setVisible(false)}
        title="Logout"
        message="Are you sure?"
        buttons={[
          {
            title: "Ok",
            onClick: handleLogout,
          },
          {
            title: "Cancel",
            color: MAIN_STYLES.gray,
            onClick: () => setVisible(false),
          },
        ]}
      />
    </>
  );
}

export default Sidebar;
