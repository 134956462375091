import React, { memo, useCallback, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import useClickOutside from "@hooks/useClickOutside";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FormControlLabel, Radio, Typography, styled } from "@mui/material";

import {
  LangItem,
  SelectContainer,
  ContentContainer,
  SelectOptionInner,
  SelectOptionContainer,
} from "./styles";

import { MAIN_STYLES } from "@constants";
import { IHotelLanguage } from "@models";
import { View } from "@components/screens";

const Title = styled(Typography)({
  color: "#111111",
  fontSize: "16px",
  fontWeight: "700",
  padding: "0 10px",
  alignSelf: "center",
  fontFamily: "Pretendard Variable, monospace",
});

const Content = styled(Typography)({
  color: "#111111",
  fontSize: "14px",
  fontWeight: "400",
  fontFamily: "Pretendard Variable, monospace",
});

interface Props {
  languages: IHotelLanguage[];
  onSubmit?: (selectedLanguages: string[]) => void;
}

const LanguageSelect: React.FC<Props> = ({ languages, onSubmit }) => {
  const { t } = useTranslation();
  const timer = useRef<NodeJS.Timeout | null>(null);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [selected, setSelected] = useState<IHotelLanguage[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useClickOutside(wrapperRef, () => setIsOpen(false));

  const handleSubmitData = useCallback(
    (data: IHotelLanguage[]) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        const keys = data.map((item) => item.code);

        onSubmit && onSubmit(keys);
      }, 500);

      return () => {
        if (timer.current) {
          clearTimeout(timer.current);
        }
      };
    },
    [onSubmit]
  );

  const renderValueView = useMemo(() => {
    if (selected.length === 0)
      return <Content>{t("ota_detailed_analysis.entire")}</Content>;
    if (selected.length === 1) return <Content>{selected[0].name}</Content>;
    return <Content>{selected.length} languages</Content>;
  }, [t, selected]);

  const handleOnShowOptions = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const renderLanguageItems = useCallback(() => {
    const addOrRemove = (lang: IHotelLanguage) => {
      let updateValues = [...selected];
      const selectedIndex = updateValues.findIndex(
        (item) => item.code === lang.code
      );
      if (selectedIndex === -1) {
        updateValues.push(lang);
        setSelected(updateValues);
      } else {
        updateValues = updateValues.filter((item) => item.code !== lang.code);
        setSelected(updateValues);
      }
      handleSubmitData(updateValues);
    };

    return languages?.map((language) => {
      return (
        <LangItem>
          <FormControlLabel
            label={
              <Typography
                color="inherit"
                sx={{ fontWeight: "700", fontSize: "16px" }}
              >
                {language.name}
              </Typography>
            }
            control={
              <Radio
                key={1}
                checked={
                  selected.findIndex((item) => item.code === language.code) !==
                  -1
                }
                onClick={() => addOrRemove(language)}
                value={language.code}
                name="radio-buttons-group"
                sx={{
                  color:
                    selected.findIndex(
                      (item) => item.code === language.code
                    ) !== -1
                      ? MAIN_STYLES.mainPurple
                      : "default",
                  "&.Mui-checked": {
                    color:
                      selected.findIndex(
                        (item) => item.code === language.code
                      ) !== -1
                        ? MAIN_STYLES.mainPurple
                        : "default",
                  },
                }}
              />
            }
          />
        </LangItem>
      );
    });
  }, [handleSubmitData, languages, selected]);

  return (
    <SelectContainer ref={wrapperRef}>
      <Title sx={{ paddingBottom: 0 }}>
        {t("ota_detailed_analysis.language")}
      </Title>
      <ContentContainer onClick={handleOnShowOptions}>
        <View style={{ flex: 1 }}>{renderValueView}</View>
        {isOpen ? (
          <ArrowDropUp sx={{ color: "rgba(34, 51, 84, 0.5)" }} />
        ) : (
          <ArrowDropDownIcon sx={{ color: "rgba(34, 51, 84, 0.5)" }} />
        )}
      </ContentContainer>
      {isOpen && (
        <SelectOptionContainer>
          <SelectOptionInner>
            <View
              style={{ cursor: "pointer", marginBottom: "10px" }}
              onClick={() => {
                setSelected([]);
                handleSubmitData([]);
              }}
            >
              <Title>{t("ota_detailed_analysis.entire")}</Title>
            </View>
            {renderLanguageItems()}
          </SelectOptionInner>
        </SelectOptionContainer>
      )}
    </SelectContainer>
  );
};

export default memo(LanguageSelect);
