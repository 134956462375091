export const TYPES_COLOR = [
  { label: " typeBar1color", color: "#BBBBBB" },
  { label: " typeBar2color", color: "#A3CDFF" },
  { label: " typeBar3color", color: "#BCB4FF" },
  { label: " typeBar4color", color: "#FFC3D7" },
  { label: " typeBar5color", color: "#C7FCD2" },
  { label: " typeBar6color", color: "#FFB985" },
  { label: " typeBar7color", color: "#EBFD73" },
  { label: " typeBar8color", color: "#E3A8FF" },
  { label: "typeLine1color", color: "#00BDE7" },
  { label: "typeLine2color", color: "#16CE55" },
  { label: "typeDash1color", color: "#FF8A00" },
  { label: "typeDash2color", color: "#00BDE6" },
];
export const LINE_COLOR = [
  { label: "typeLine1color", color: "#111111" },
  { label: "typeLine2color", color: "#BBBBBB" },
];
export const SOLID_COLOR = [
  { label: "typeDash1color", color: "#FF8A00" },
  { label: "typeDash2color", color: "#00BDE6" },
  { label: "typeDash3color", color: "#16CE55" },
];

export const CATEGORY_COLOR = {
  호텔: "#5ADBD7",
  객실: "#EBFD73",
  서비스: "#FFC3D7",
  "위치&교통": "#FFB985",
  가격: "#E3A8FF",
  청결: "#A3CDFF",
  기본시설: "#C7FCD2",
  특수시설: "#BCB4FF"
};
