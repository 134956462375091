import React, { useRef, useState, useEffect } from "react";

import {
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  BarController,
  Chart as ChartJS,
} from "chart.js";
import { Box, IconButton } from "@mui/material";
import { Chart, getElementsAtEvent } from "react-chartjs-2";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { RankChartData } from "./types";
import { LINE_COLOR } from "./constants";
import { SAMPLE_HOTEL_COLORS } from "@pages/detailedAnalysis/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarController
);

interface ChartItemProp {
  backgroundColor?: string;
  data: number[];
  label: string;
  color?: string;
}
interface Props {
  canNext?: boolean;
  maxXaxis?: number;
  currentColumn: number;
  canPrevious?: boolean;
  chartData?: RankChartData;
  onNext?: (callback?: () => void) => void;
  onChangeColumn?: (column: number) => void;
  onPrevious?: (callback?: () => void) => void;
}

interface Data {
  labels: string[];
  datasets: any;
}
const MAX_COLUMN = 5;
const RankChart: React.FC<Props> = ({
  canNext,
  chartData,
  canPrevious,
  maxXaxis = 0,
  currentColumn,
  onNext,
  onChangeColumn,
  onPrevious,
}) => {
  const activeIndexRef = useRef(currentColumn);
  const chartRef = useRef<ChartJS | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCurrentIndex] = useState<number>(currentColumn);
  const [data, setData] = useState<Data | null>(null);

  useEffect(() => {
    setCurrentIndex(currentColumn);
    activeIndexRef.current = currentColumn;
  }, [currentColumn]);

  useEffect(() => {
    let datasets: any = [];
    const lineChart = chartData?.dataChart?.lineChart;
    if (lineChart) {
      lineChart.forEach((_: ChartItemProp, index: number) => {
        datasets.push({
          type: "line",
          data: _.data,
          label: _.label,
          borderColor:
            _.label === "MY HOTEL" ? "#961BED" : SAMPLE_HOTEL_COLORS[index],
          borderWidth: 3,
          fill: false,
          pointBackgroun: "white",
          pointBorderColor:
            _.label === "MY HOTEL" ? "#961BED" : SAMPLE_HOTEL_COLORS[index],
          pointStyle: "circle",
          pointRadius: 8,
        });
      });
    }

    const solidChart = chartData?.dataChart?.solidChart;
    if (solidChart) {
      solidChart.forEach((_: ChartItemProp, index: number) => {
        datasets.push({
          type: "line",
          data: _.data,
          label: _.label,
          fill: false,
          backgroundColor: LINE_COLOR[index],
          borderColor: LINE_COLOR[index],
          borderDash: [5, 5],
          pointBackgroundColor: "white",
          pointBorderColor: LINE_COLOR[index],
          pointStyle: "circle",
        });
      });
    }

    setData({
      labels: chartData?.label || [],
      datasets: datasets,
    });
  }, [chartData]);

  const resetActiveIndex = () => {
    setCurrentIndex(data?.labels ? data?.labels.length - 1 : MAX_COLUMN);
    activeIndexRef.current = data?.labels
      ? data?.labels.length - 1
      : MAX_COLUMN;
  };

  const handlePrevious = () => {
    onPrevious && onPrevious(resetActiveIndex);
  };

  const handleNext = () => {
    onNext && onNext(resetActiveIndex);
  };

  const options: any = {
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          font: {
            size: 16,
            weight: "bold",
          },
        },
      },
      y: {
        beginAtZero: false,
        min: 0,
        max: Math.round(maxXaxis / 10) * 10 + 100,
      },
    },
    onClick: (event: any, _: any, chart: any) => {
      const clickX = event.x;
      const columnWidth =
        chart.width / (data?.labels ? data?.labels.length : 6);
      const clickedColumnIndex = Math.floor(
        (chart.width - (chart.width - clickX)) / columnWidth
      );
      activeIndexRef.current = clickedColumnIndex;
      setCurrentIndex(clickedColumnIndex);
      onChangeColumn && onChangeColumn(clickedColumnIndex);
    },
  };

  const chartBackgroundPlugin = {
    id: "chartBackgroundPlugin",
    beforeDraw: (chart: any) => {
      if (activeIndexRef.current !== null) {
        const ctx = chart.ctx;
        const chartArea = chart.chartArea;
        const xAxis = chart.scales.x;

        const barWidth =
          xAxis.getPixelForTick(1) -
          xAxis.getPixelForTick(0) -
          (chart.options.scales.x.ticks.padding || 0);

        const barLeft =
          xAxis.getPixelForTick(activeIndexRef.current) - barWidth / 2;

        ctx.save();
        ctx.globalCompositeOperation = "destination-over";
        ctx.fillStyle = "#AD00FF0D";

        ctx.fillRect(
          barLeft,
          chartArea.top,
          barWidth,
          chartArea.bottom - chartArea.top
        );

        ctx.restore();
      }
    },
  };

  const onClickChart = (event: any) => {
    if (
      chartRef?.current &&
      getElementsAtEvent(chartRef?.current, event).length > 0
    ) {
      const datasetPoint = getElementsAtEvent(chartRef.current, event)[0].index;
      if (data && data.datasets !== undefined) {
        onChangeColumn && onChangeColumn(datasetPoint);
        setCurrentIndex(datasetPoint);
        activeIndexRef.current = datasetPoint;
      }
    }
  };

  return (
    <>
      {data && (
        <Box sx={{ width: "100%", height: "430px", position: "relative" }}>
          <IconButton
            disabled={canPrevious}
            onClick={handlePrevious}
            sx={{ position: "absolute", top: "40%", left: -45 }}
          >
            <ChevronLeftIcon
              style={{
                height: 50,
                width: 50,
              }}
            />
          </IconButton>

          <Chart
            type="bar"
            data={data}
            ref={chartRef}
            options={options}
            onClick={onClickChart}
            plugins={[chartBackgroundPlugin]}
          />
          <IconButton
            disabled={canNext}
            onClick={handleNext}
            sx={{ position: "absolute", top: "40%", right: -45 }}
          >
            <ChevronRightIcon
              style={{
                height: 50,
                width: 50,
              }}
            />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default RankChart;
