import React from "react";
import keycloak, { initOptions } from "./keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import { RecoilRoot } from "recoil";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import "./utils/i18n";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SidebarProvider } from "./contexts/SidebarContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
    // <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
      <RecoilRoot>
        <HelmetProvider>
          <SidebarProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SidebarProvider>
      </HelmetProvider>
    </RecoilRoot>
    // </ReactKeycloakProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
