import styled from "styled-components";
import ReactPaginate from "react-paginate";

import { View } from "./screens";

const MyPaginate = styled(ReactPaginate).attrs({
  activeClassName: "active",
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
  font-size: 17px;
  color: #999999;
  li a {
    padding: 0.1rem 1rem;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active a {
    border-color: transparent;
    color: #961bed;
    font-weight: bold;
    min-width: 32px;
  }
  li.disabled a {
    color: #999999;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

interface PaginationProps {
  totalCount: number;
  itemsPerPage?: number;
  setOffset: (offset: number) => void;
}

export function Pagination({
  totalCount,
  itemsPerPage = 20,
  setOffset,
}: PaginationProps) {
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = ({ selected }: { selected: number }) => {
    const currentPage = selected + 1;
    setOffset(currentPage);
  };

  return (
    <View
      style={{ padding: 15, justifyContent: "center", alignItems: "center" }}
    >
      {totalCount > 0 && (
        <MyPaginate
          initialPage={0}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          pageCount={pageCount}
          previousLabel="<"
          nextLabel=">"
        />
      )}
    </View>
  );
}
