import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, menu, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  main, menu, nav, output, ruby, section, summary,
  time, mark, audio, video, input, select {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, main, menu, nav, section {
    display: block;
  }
  /* HTML5 hidden-attribute fix for newer browsers */
  *[hidden] {
      display: none;
  }
 
  menu, ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  * {
    box-sizing: border-box;
    font-family: NanumGothic;
  }
  body {
    font-family: NanumGothic;
  }
 
  a {
    text-decoration:  none;
    color: inherit;
  }
  div {
    display: flex;
    flex-direction: column;
  }

  .txt-area {
    height: 464px !important;
  }

  @media only screen and (max-width: 600px) {
    .web-row {
      padding: 20px 10px !important;
    }
    .web-title {
      font-size: 18px !important;
    }
    .copy-ico img, .trash-ico img{
      width: 33px !important;
      height: 33px !important;
    }
    .plus-ico {
      width: 33px !important;
      height: 33px !important;
    }
    .back-ico-container {
      position: absolute;
      left: 3.12% !important;
      right: 89.62% !important;
      top: 22.68% !important;
      bottom: 9.68% !important;
    }
    .back-ico {
      width: 36.45px !important;
      height: 35px !important;
    }
    .chk-ico {
      width: 20px !important;
      height: 20px !important;
    }
    .close-ico {
      width: 30px !important;
      height: 30px !important;
    }
    .social-ico {
      width: 36px !important;
      height: 36px !important;
    }
    .txt-14 {
      font-size: 12px !important;
    }
    .txt-18 {
      font-size: 12px !important;
    }
    .txt-20 {
      font-size: 14px !important;
    }
    .txt-24 {
      font-size: 18px !important;
      line-height: 28px !important;
    }
    .txt-28 {
      font-size: 22px !important;
    }
    .txt-34 {
      font-size: 28px !important;
    }
    .btn {
      padding: 25px 24px !important;
    }
    .btn div {
      font-size: 18px !important;
    }
    .notice-img {
      width: 40px !important;
      height: 40px !important;
    }
    .input-container {
      padding: 20px 12px !important;
    }
    .input-container input {
      font-size: 12px !important;
    }
    .product-category-container {
      height: 132px !important;
    }
    .category-img {
      width: 40px !important;
      height: 40px !important;
    }
    .Toastify__toast {
      min-height: 42px !important;
    }
    .Toastify__toast-body div {
      font-size: 14px !important;
    }
  }

  @media only screen and (max-width: 480px) {
    .Toastify__toast-container {
      width: 100% !important;
    }
  }
`;

export const FmStyle = createGlobalStyle`
  div {
    display: block;
    flex-direction: initial;
  }
`;
