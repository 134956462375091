import styled from "styled-components";

export const SelectContainer = styled.div`
  display: flex;
  position: relative;
`;

export const ContentContainer = styled.div`
  display: flex;
  cursor: pointer;
  min-width: 200px;
  padding: 8px 10px;
  border-radius: 5px;
  align-items: center;
  flex-direction: row;
  background-color: white;
  border: 1px #cccccc solid;
  &:hover {
    border: 1px rgba(0, 0, 0, 0.23) solid;
  }
`;

export const SelectOptionContainer = styled.div`
  top: 60px;
  left: 50px;
  padding: 8px 10px;
  position: absolute;
  border-radius: 12px;
  background-color: white;
  border: 2px #961bed solid;
`;

export const SelectOptionInner = styled.div`
  width: 300px;
  height: 500px;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
`;

export const LangItem = styled.div``;
