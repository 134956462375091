import React, { PropsWithChildren, ReactNode } from "react";
import { StyledDialog } from "./styles";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  BoxProps,
  Box,
  DialogProps,
} from "@mui/material";
import { Close } from "@mui/icons-material";

export interface IBaseDialogProps extends DialogProps {
  onClose: () => void;
  title?: string;
  footerAlign?: BoxProps["alignItems"];
  dialogActions?: ReactNode | ReactNode[];
  closeButton?: boolean;
}

const BaseDialog: React.FC<PropsWithChildren<IBaseDialogProps>> = ({
  title,
  dialogActions,
  open,
  footerAlign,
  children,
  closeButton,
  onClose,
  ...props
}) => {
  return (
    <StyledDialog open={open} onClose={onClose} {...props}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {closeButton && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      )}
      <DialogContent>{children}</DialogContent>
      {dialogActions && (
        <DialogActions>
          <Box display="flex" justifyContent={footerAlign}>
            {dialogActions}
          </Box>
        </DialogActions>
      )}
    </StyledDialog>
  );
};

export default BaseDialog;
