import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: 8,
  padding: "10px",
});

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: theme.palette.common.white,
    backgroundColor: theme.colors.customize.purple,
  },
  "&.MuiToggleButtonGroup-grouped": {
    borderRadius: "8px !important",
    margin: "3px 10px",
    border: "1px solid lightgrey !important",
    padding: "8px 10px 8px 10px",
  },
  "&:hover":{
    color: theme.palette.common.white,
    backgroundColor: theme.colors.customize.purple,
  },
  maxWidth: "fit-content",
  backgroundColor: theme.palette.common.white,
  color: theme.colors.customize.purple,
}));
