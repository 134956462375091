import { Box, BoxProps } from "@mui/material";
import React, { ReactNode } from "react";

interface BoxFlexProps extends BoxProps {
  children: ReactNode;
  sx?: any;
}

const BoxFlex: React.FC<BoxFlexProps> = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", ...sx }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default BoxFlex;
