import { useDraggable } from "@hooks";
import { IHeaderItem } from "@models";
import { PropsWithChildren, useRef } from "react";
import { ListItemHeader } from "./header";
import { ItemBodyContainer, ListBorder, ListHeaderContainer } from "./screens";

interface ItemListProps extends PropsWithChildren {
  listHeaderItem: IHeaderItem[];
  aspectRatio?: number;
}
export function ItemList({
  listHeaderItem,
  aspectRatio = 9 / 16,
  children,
}: ItemListProps) {
  const tableRef = useRef<HTMLTableElement>(null);

  const { events } = useDraggable(tableRef as React.MutableRefObject<any>, {
    decayRate: 0.96,
    safeDisplacement: 11,
    applyRubberBandEffect: true,
  });

  return (
    <ListBorder ref={tableRef} {...events} aspectRatio={aspectRatio}>
      <ListHeaderContainer>
        <ListItemHeader items={listHeaderItem} tableRef={tableRef} />
      </ListHeaderContainer>
      <ItemBodyContainer>{children}</ItemBodyContainer>
    </ListBorder>
  );
}
