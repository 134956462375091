import { CategoryItem } from "src/types";

export const SELECTED_KEYWORDS = {
  전체: [
    {
      id: 0,
      type: "all",
      label: "전체",
      bg: "#F5E4FF",
      keyword: "전체",
      color: "#961BED",
      categoryName: "전체",
    },
  ],
  호텔: [],
  객실: [],
  서비스: [],
  "위치&교통": [],
  가격: [],
  청결: [],
  특수시설: [],
  기본시설: [],
};

export const CATEGORY_LIST: CategoryItem[] = [
  {
    id: undefined,
    name: "전체",
    progress: 0,
    positive: 0,
    negative: 0,
    keywords: [],
  },
];

export const chartInfo = [
  { id: 1, title: "총", amount: "8,887" },
  { id: 1, title: "긍정", amount: "7,948" },
  { id: 1, title: "부정", amount: "939" },
];

export const SAMPLE_COLORS = [
  "#780cc4",
  "#8519d1",
  "#9226de",
  "#9f33eb",
  "#ab3ff7",
  "#b84cff",
  "#c559ff",
  "#d266ff",
  "#de72ff",
  "#eb7fff",
  "#f88cff",
  "#ff99ff",
  "#ffa5ff",
  "#ffb2ff",
  "#ffbfff",
  "#ffccff",
  "#ffd8ff",
  "#ffe5ff",
  "#fff2ff",
  "#ffffff",
];

export const SAMPLE_HOTEL_COLORS = [
  "#00BDE6",
  "#16CE55",
  "#E94EAB",
  "#92E94E",
  "#FF8A00",
  "#F6CF00",
  "#C62828",
  "#283593",
  "#AD1457",
  "#00695C",
  "#FFB300",
  "#4E342E",
  "#37474F",
  "#558B2F",
  "#6D4C41",
];
