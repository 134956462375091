import * as React from "react";
import { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import GradientOpacityBox from "@components/GradientOpacityBox";
import { IReview } from "@models";

const UsedReply: React.FC<{ review: IReview }> = ({ review }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={{ position: "relative", padding: "20px" }}>
      {expanded === true ? (
        <>
          <Box
            sx={{
              width: 400,
              height: expanded ? "auto" : 100,
              position: "relative",
              p: 2,
              boxSizing: "border-box",
              overflow: "hidden",
              paddingBottom: expanded ? "0px" : "15px",
            }}
          >
            <Typography component="div">{review.content}</Typography>
          </Box>
        </>
      ) : (
        <GradientOpacityBox>{review.content}</GradientOpacityBox>
      )}

      {review.content.length > 120 && (
        <IconButton
          color="primary"
          onClick={handleExpandClick}
          sx={[
            {
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              bottom: "-20px",
              bgcolor: "#F5E4FF",
              borderRadius: "50%",
            },
            expanded === true && {
              position: "relative",
              marginBottom: "20px",
            },
          ]}
        >
          {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      )}
    </Box>
  );
};

export default UsedReply;
