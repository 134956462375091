import { ITextSize, ITextWeight } from "@models";

export function toPixelValue(value: string | number): string {
  return typeof value === "string" ? value : `${value}px`;
}

export function getFontSize(size: ITextSize) {
  switch (size) {
    case "4xs":
      return 7;
    case "3xs":
      return 8;
    case "2xs":
      return 10;
    case "xs":
      return 11.5;
    case "sm":
      return 13;
    case "md":
      return 14;
    case "lg":
      return 16;
    case "xl":
      return 18;
    case "2xl":
      return 20;
    case "2.4xl":
      return 24;
    case "2.5xl":
      return 25;
    case "2.8xl":
      return 28;
    case "3xl":
      return 30;
    case "3.4xl":
      return 34;
    case "4xl":
      return 36;
    case "5xl":
      return 48;
    case "6xl":
      return 60;
    case "7xl":
      return 72;
    case "8xl":
      return 96;
    case "9xl":
      return 128;
    default:
      return 16;
  }
}

export function getFontWeight(weight: ITextWeight) {
  switch (weight) {
    case "thin":
      return 100;
    case "extrathin":
      return 200;
    case "light":
      return 300;
    case "normal":
      return 400;
    case "medium":
      return 500;
    case "semibold":
      return 600;
    case "bold":
      return 700;
    case "extrabold":
      return 800;
    case "black":
      return 900;
    default:
      return 400;
  }
}

export const DEFAULT_FONTS = {
  Thin: "Pretendard-Thin",
  ExtraLight: "Pretendard-ExtraLight",
  Light: "Pretendard-Light",
  Regular: "Pretendard-Regular",
  Medium: "Pretendard-Medium",
  SemiBold: "Pretendard-SemiBold",
  Bold: "Pretendard-Bold",
  ExtraBold: "Pretendard-Bold",
  Black: "Pretendard-Black",
};
