import React, { useRef, useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarController,
} from "chart.js";
import { Chart, getElementsAtEvent } from "react-chartjs-2";
import { LINE_COLOR, SOLID_COLOR, TYPES_COLOR } from "./constants";
import { Box, IconButton, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BoxFlex from "@components/BoxFlex";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarController
);

ChartJS.defaults.datasets.bar.maxBarThickness = 82;
ChartJS.defaults.datasets.bar.borderRadius = 12;
interface TypeColorItems {
  label: string;
  color: string;
}

interface ChartItemProp {
  backgroundColor?: string;
  data: number[];
  label: string;
  color?: string;
  activeColor: string;
  activeIndex: number;
}
interface ChartProp {
  page?: number;
  label?: string[];
  year?: number;
  dataChart: {
    barChart?: ChartItemProp[];
    lineChart?: ChartItemProp[];
    solidChart?: ChartItemProp[];
  };
}
interface Props {
  totalPages: number;
  isHover?: boolean;
  chartData: ChartProp[];
  handleClick?: (data: {
    year?: number;
    label?: string;
    currentPage: number;
    column: number;
  }) => void;
}

interface Data {
  labels: any;
  datasets: any;
}

const RankBarChart: React.FC<Props> = ({
  chartData,
  isHover = false,
  totalPages,
  handleClick,
}) => {
  const chartRef = useRef<ChartJS | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<Data | null>(null);
  const [maxYvalue, setMaxYvalue] = useState<number>(0);
  const [barColors, setBarColors] = useState<TypeColorItems[]>(TYPES_COLOR);

  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [activeColor, setActiveColor] = useState<string>("#111");

  const [tooltip, setTooltip] = useState<{
    display: boolean;
    top: string;
    left: string;
  }>({ display: false, top: "0px", left: "0px" });

  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  useEffect(() => {
    const currentChartData = chartData.find(
      (data) => data.page === currentPage
    );
    const barCharts = currentChartData?.dataChart?.barChart;
    if (barCharts) {
      setMaxYvalue(Math.ceil(Math.max(...barCharts[0].data) / 10) * 10);
    }
  }, [chartData, currentPage]);

  useEffect(() => {
    let datasets: any = [];
    let currentData = chartData.find((data) => data.page === currentPage);
    if (currentData?.dataChart?.solidChart) {
      currentData?.dataChart?.solidChart.map(
        (_: ChartItemProp, index: number) => {
          datasets.push({
            type: "line",
            data: _.data,
            label: _.label,
            fill: false,
            backgroundColor: _.color ? _.color : SOLID_COLOR[index].color,
            borderColor: _.color ? _.color : SOLID_COLOR[index].color,
            borderDash: [5, 5],
            pointBackgroundColor: "white",
            pointBorderColor: _.color ? _.color : SOLID_COLOR[index].color,
            pointStyle: "circle",
          });
          return null;
        }
      );
    }
    if (currentData?.dataChart?.lineChart) {
      currentData?.dataChart?.lineChart.map(
        (_: ChartItemProp, index: number) => {
          datasets.push({
            type: "line",
            data: _.data,
            label: _.label,
            borderColor: _.color ? _.color : LINE_COLOR[index].color,
            borderWidth: 2,
            fill: false,
            pointBackgroundColor: "white",
            pointBorderColor: _.color ? _.color : LINE_COLOR[index].color,
            pointStyle: "circle",
          });
          return null;
        }
      );
    }
    if (currentData?.dataChart?.barChart) {
      currentData?.dataChart?.barChart.forEach((_: ChartItemProp) => {
        const backgroundColor: any[] = [];
        setActiveColor(_.activeColor);
        setActiveIndex(_.activeIndex);
        for (let i = 0; i < _.data.length; i++) {
          if (i === _.activeIndex) backgroundColor.push(_.activeColor);
          backgroundColor.push(_.color);
        }
        datasets.push({
          type: "bar",
          label: _.label,
          data: _.data,
          backgroundColor,
        });
      });
    }

    const data: any = {
      labels: currentData?.label,
      datasets: datasets,
    };
    setData(data);
  }, [barColors, chartData, currentPage]);

  const chartBackgroundPlugin = {
    id: "chartBackgroundPlugin",
    beforeDraw: (chart: any) => {
      const ctx = chart.ctx;
      const chartArea = chart.chartArea;
      const xAxis = chart.scales.x; // Reference to the x-axis

      const barWidth =
        xAxis.getPixelForTick(1) -
        xAxis.getPixelForTick(0) -
        (chart.options.scales.x.ticks.padding || 0);

      const barLeft = xAxis.getPixelForTick(activeIndex) - barWidth / 2;

      ctx.save();
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = "#AD00FF0D";

      ctx.fillRect(
        barLeft,
        chartArea.top,
        barWidth,
        chartArea.bottom - chartArea.top
      );

      ctx.restore();
    },
  };

  const options: any = {
    maintainAspectRatio: false,
    scales: {
      x: {
        min: 0,
        max: 40,
        ticks: {
          color: function (context: any) {
            if (context.index === activeIndex) {
              return activeColor;
            } else {
              return "#111";
            }
          },
          font: {
            size: 16,
            weight: "bold",
          },
        },
      },
      y: {
        beginAtZero: true,
        min: maxYvalue || 0,
        max: 0,
      },
    },
    // plugins: {
    //   tooltip: {
    //     enabled: false,
    //     external: (context: any) => {
    //       const { chart, tooltip } = context;

    //       console.log("tooltip.opacity: >>", tooltip.opacity);

    //       if (tooltip.opacity === 0) {
    //         if (tooltip.display)
    //           setTooltip({ display: false, top: "0px", left: "0px" });
    //         return;
    //       }
    //       const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    //       setTooltip({
    //         display: true,
    //         top: positionY + tooltip.caretY + 400 + "px",
    //         left: positionX + tooltip.caretX + "px",
    //       });
    //     },
    //   },
    // },
  };

  const onClickChart = (event: any) => {
    if (
      chartRef?.current &&
      getElementsAtEvent(chartRef?.current, event).length > 0
    ) {
      const datasetPoint = getElementsAtEvent(chartRef.current, event)[0].index;
      if (data && data.datasets !== undefined) {
        let currentData: any = chartData.find(
          (data) => data.page === currentPage
        );
        handleClick &&
          handleClick({
            year: currentData?.year,
            label: currentData?.label[datasetPoint],
            currentPage: currentPage,
            column: datasetPoint,
          });
      }
    }
  };

  if (activeIndex < 0) return <></>

  return (
    <>
      {data && (
        <>
          <Box sx={{ width: "100%", height: "430px", position: "relative" }}>
            {totalPages > 1 && (
              <IconButton
                onClick={handlePrevious}
                disabled={currentPage === 1}
                sx={{ position: "absolute", top: "50%", left: -25 }}
              >
                <ChevronLeftIcon />
              </IconButton>
            )}
            <Chart
              type="bar"
              data={data}
              ref={chartRef}
              id="rankChart"
              options={options}
              onClick={onClickChart}
              plugins={[chartBackgroundPlugin]}
            />
            {totalPages > 1 && (
              <IconButton
                sx={{ position: "absolute", top: "50%", right: -25 }}
                onClick={handleNext}
                disabled={currentPage === totalPages}
              >
                <ChevronRightIcon />
              </IconButton>
            )}
          </Box>
          {tooltip.display && (
            <div
              style={{
                position: "absolute",
                top: tooltip.top,
                left: tooltip.left,
                background: "white",
                padding: "20px",
                borderRadius: "12px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Box sx={{ width: "250px" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
                  MY HOTEL
                </Typography>
                <BoxFlex
                  sx={{
                    margin: "10px 0px",
                    padding: "20px",
                    background: "#FAF3FE",
                    borderRadius: "12px",
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        background: "#961BED",
                        borderRadius: "20%",
                        color: "white",
                        padding: "5px",
                        fontSize: "9px",
                      }}
                    >
                      상위 11%
                    </Box>
                    <span
                      style={{
                        color: "#961BED",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      18위
                    </span>
                    /
                    <span
                      style={{
                        color: "black",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      257
                    </span>
                  </Box>
                  <Typography
                    sx={{
                      color: "#961BED",
                      fontSize: "24px",
                      fontWeight: "700",
                    }}
                  >
                    121.5점
                  </Typography>
                </BoxFlex>
              </Box>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default RankBarChart;
