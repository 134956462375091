import { atom } from "recoil";
import { IHotelLanguage, ISignUpFlowForm, IUserInfo } from "@models";
import { STORAGE_KEYS, localStorageEffect } from "@utils";
import { IReviewFilter, IToastData } from "src/types";
import { IBanner } from "@pages/admin/bannerManagement/types";

export const USER_INFO = atom<IUserInfo>({
  key: "USER_INFO",
  default: undefined,
  effects: [localStorageEffect(STORAGE_KEYS.user)],
});

export const HOTEL_LANGUAGE_INFO = atom<IHotelLanguage[]>({
  key: "HOTEL_LANGUAGE_INFO",
  default: [],
});

export const ANALYSIS_TYPE = atom<string | undefined>({
  key: "ANALYSIS_TYPE",
  default: "review",
});

export const PERIOD_TYPE = atom<"week" | "month">({
  key: "PERIOD_TYPE",
  default: "month",
});

export const SIGN_UP_FORM = atom<ISignUpFlowForm>({
  key: "SIGN_UP_FORM",
  default: {
    selectedOtas: [],
    myHotel: undefined,
    isVerifiedEmail: false,
    isAllowUseOfUserData: false,
    isVerifiedPhoneNumber: false,
  },
});

export const SHOW_LOADING = atom<boolean>({
  key: "SHOW_LOADING",
  default: false,
});

export const STRIP_BANNER = atom<IBanner>({
  key: "STRIP_BANNER",
  default: undefined,
});

export const POPUP_BANNER = atom<IBanner[]>({
  key: "POPUP_BANNER",
  default: [],
});

export const TOAST_DATA = atom<IToastData>({
  key: "TOAST_DATA",
  default: {
    open: false,
    message: "",
    vertical: "bottom",
    horizontal: "center",
  },
});

export const HIDE_SIDEBAR = atom<boolean>({
  key: "HIDE_SIDEBAR",
  default: false,
});

export const IS_FIRST_SETTING_SUCCESS = atom<boolean>({
  key: "IS_FIRST_SETTING_SUCCESS",
  default: false,
});

export const REVIEW_FILTER = atom<IReviewFilter>({
  key: "REVIEW_FILTER",
  default: {
    reply: "ALL",
  },
});

export const FIRST_MODAL = atom<boolean>({
  key: "FIRST_MODAL",
  default: false,
});

export const SECOND_MODAL = atom<boolean>({
  key: "SECOND_MODAL",
  default: false,
});
